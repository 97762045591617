import React from 'react';
import CollapsableInformationField from '../../../../Shared/Components/CollapsableContent/CollapsableInformationField';
import {Substance} from '../../../../Models/Types/Substance';
import ComponentItem from '../../../../Shared/Components/CollapsableContent/SubComponents/ComponentItem';
import {generateFlatStatements} from '../../../../Shared/Functions/generateStatements';
import generateFlatTitleList from '../../../../Shared/Functions/generateFlatList';
import generateSymbolList from '../../../../Shared/Functions/generateSymbolList';
import {ProductTypes} from '../../enums';
import ComponentList from './ComponentList/ComponentList';

type PropType = {
  substanceData: Substance;
};
const GeneralInformation = (props: PropType) => {
  const {substanceData} = props;

  const {
    name,
    article_number,
    synonyms,
    environmental_risks,
    vib_version,
    mpz_number,
    company,
    signal_words,
    purpose,
    hazard_class,
    hazard_statements,
    precautionary_statements,
    first_aid_inhalation,
    first_aid_ingestion,
    first_aid_skin_contact,
    first_aid_hand_contact,
    first_aid_eye_contact,
    special_workplace_provisions,
    exposure_hazards_and_protection,
    personal_precautions,
    environmental_precautions,
    cleaning_methods,
    handling,
    storage,
    specific_forms_of_use,
    suitable_extinguishing_media,
    unsuitable_extinguishing_media,
    respiratory_protection,
    hand_protection,
    ingestion_protection,
    eye_protection,
    skin_protection,
    control_occupational_exposure,
    other_control_measures,
    physical_state,
    color,
    odor,
    un_number,
    inhalation,
    skin,
    eyes,
    hands,
    ingestion,
    other_toxicological_information,
    water_hazard,
    other_harmful_effects,
    transport_information,
    proper_shipping_name,
    packaging_group,
    product_disposal,
    packaging_disposal,
    info_1,
    info_2,
    supplier_vib_date,
    vib_revision_date,
    modified_information,
    symbol_ghs,
    components,
    product_type,
    cas_number,
    eec_number,
    eg_number,
    iupac_name,
    molecule_formula,
    mac_value,
    disposal_instructions,
    other_relevant_information,
    history,
    symbol_pbm,
    symbol_adr,
  } = substanceData;

  function returnChapterTwo() {
    if (product_type !== ProductTypes.Preparation) {
      return (
        <>
          <ComponentItem item={{title: 'CAS-nummer', description: cas_number}}/>
          <ComponentItem item={{title: 'EEC-catalogusnummer', description: eec_number}}/>
          <ComponentItem item={{title: 'EINECS / ELINCS nummer', description: eg_number}}/>
          <ComponentItem
            item={{
              title: 'IUPAC-naam (International Union of Pure and Applied Chemistry)',
              description: iupac_name,
            }}
          />
          <ComponentItem item={{title: 'Molecuul formule', description: molecule_formula}}/>
          <ComponentItem item={{title: 'Mac waarde', description: mac_value}}/>
          {components.length > 0 && <ComponentList substanceComponents={components}/>}
        </>
      );
    }

    return <ComponentList substanceComponents={components}/>;
  }

  return (
    <ul className="information-list">
      <CollapsableInformationField
        key="1"
        title="1. Identificatie van de stof"
        open
        symbols={generateSymbolList(symbol_ghs)}
        signalWords={signal_words}
      >
        <ComponentItem item={{title: 'Productnaam', description: name}}/>
        <ComponentItem item={{title: 'Artikelnummer', description: article_number}}/>
        <ComponentItem item={{title: 'MPZ nummer', description: mpz_number}}/>
        <ComponentItem item={{title: 'Gebruikers doeleinde', description: purpose}}/>
        <ComponentItem item={{title: 'Fysische toestand', description: physical_state?.title}}/>
        <ComponentItem item={{title: 'Kleur', description: color}}/>
        <ComponentItem item={{title: 'Geur', description: odor}}/>
        <ComponentItem item={{title: 'Synoniem', description: synonyms}}/>
        <ComponentItem item={{title: 'Leverancier', list: [company?.name, company?.contact]}}/>
      </CollapsableInformationField>
      <CollapsableInformationField
        key="2"
        title="2. Gevaren en samenstelling"
      >
        <ComponentItem item={{title: 'Gevarenklassen', list: generateFlatTitleList(hazard_class)}}/>
        <ComponentItem item={{title: 'H-Zinnen', list: generateFlatStatements(hazard_statements)}}/>
        <ComponentItem item={{title: 'P-Zinnen', list: generateFlatStatements(precautionary_statements)}}/>
        {returnChapterTwo()}
      </CollapsableInformationField>
      <CollapsableInformationField
        key="3"
        title="3. Veiligheidsmaatregelen en hantering"
        symbols={generateSymbolList(symbol_pbm)}
      >
        <ComponentItem item={{title: 'Inademing', description: first_aid_inhalation}}/>
        <ComponentItem item={{title: 'Inslikken', description: first_aid_ingestion}}/>
        <ComponentItem item={{title: 'Huidcontact', description: first_aid_skin_contact}}/>
        <ComponentItem item={{title: 'Handcontact', description: first_aid_hand_contact}}/>
        <ComponentItem item={{title: 'Oogcontact', description: first_aid_eye_contact}}/>
        <ComponentItem
          item={{
            title: 'Noodzakelijke speciale voorzieningen op werkplek',
            description: special_workplace_provisions,
          }}
        />

        <ComponentItem
          item={{title: 'Geschikte blusmiddelen', list: generateFlatTitleList(suitable_extinguishing_media)}}
        />
        <ComponentItem
          item={{title: 'Ongeschikte blusmiddelen', list: generateFlatTitleList(unsuitable_extinguishing_media)}}
        />
        <ComponentItem item={{title: 'Gevaren', description: exposure_hazards_and_protection}}/>
        <ComponentItem item={{title: 'Persoonlijke voorzorgsmaatregelen', description: personal_precautions}}/>
        <ComponentItem item={{title: 'Reinigingsmethoden', description: cleaning_methods}}/>
        <ComponentItem item={{title: 'Bescherming ademhalingsorganen', description: respiratory_protection}}/>
        <ComponentItem item={{title: 'Bescherming handen', description: hand_protection}}/>
        <ComponentItem item={{title: 'Bescherming ogen', description: eye_protection}}/>
        <ComponentItem item={{title: 'Bescherming huid', description: skin_protection}}/>
        <ComponentItem item={{title: 'Bescherming inslikken', description: ingestion_protection}}/>
        <ComponentItem
          item={{title: 'Beheersing beroepsmatige blootstelling', description: control_occupational_exposure}}
        />
        <ComponentItem item={{title: 'Overige beheersmaatregelen', description: other_control_measures}}/>
      </CollapsableInformationField>
      <CollapsableInformationField
        key="4"
        title="4. Product en milieu"
      >
        <ComponentItem item={{title: 'Hantering', description: handling}}/>
        <ComponentItem item={{title: 'Opslag', description: storage}}/>
        <ComponentItem item={{title: 'Specifieke vorm(en) van gebruik', description: specific_forms_of_use}}/>
        <ComponentItem item={{title: 'Inademen', description: inhalation}}/>
        <ComponentItem item={{title: 'Huid', description: skin}}/>
        <ComponentItem item={{title: 'Handen', description: hands}}/>
        <ComponentItem item={{title: 'Ogen', description: eyes}}/>
        <ComponentItem item={{title: 'Inslikken', description: ingestion}}/>
        <ComponentItem
          item={{title: 'Overige toxicologische informatie', description: other_toxicological_information}}
        />
        <ComponentItem item={{title: 'Milieuvoorzorgsmaatregelen', description: environmental_precautions}}/>
        <ComponentItem item={{title: 'Milieurisico', description: environmental_risks}}/>
        <ComponentItem item={{title: 'Waterbezwaarlijkheid', description: water_hazard}}/>
        <ComponentItem item={{title: 'Andere schadelijke effecten', description: other_harmful_effects}}/>
      </CollapsableInformationField>
      <CollapsableInformationField
        key="5"
        title="5. Afval en transport"
        symbols={generateSymbolList(symbol_adr)}
      >
        <ComponentItem item={{title: 'Product', description: product_disposal}}/>
        <ComponentItem item={{title: 'Verpakkingsafval', description: packaging_disposal}}/>
        <ComponentItem item={{title: 'Afval instructies', description: disposal_instructions}}/>
        <ComponentItem item={{title: 'UN-nummer', description: un_number}}/>
        <ComponentItem item={{title: 'Juiste ladingsnaam', description: proper_shipping_name}}/>
        <ComponentItem item={{title: 'Verpakkingsgroep', description: packaging_group?.title}}/>
        <ComponentItem item={{title: 'Andere relevante informatie', description: transport_information}}/>
      </CollapsableInformationField>
      <CollapsableInformationField
        key="6"
        title="6. Informatie"
      >
        <ComponentItem item={{title: 'Versie', description: vib_version}}/>
        <ComponentItem item={{title: 'VIB datum leverancier', description: supplier_vib_date}}/>
        <ComponentItem item={{title: 'VIB herzieningsdatum', description: vib_revision_date}}/>
        <ComponentItem item={{title: 'Informatie 1', description: info_1}}/>
        <ComponentItem item={{title: 'Informatie 2', description: info_2}}/>
        <ComponentItem item={{title: 'Andere relevante informatie', description: other_relevant_information}}/>
        <ComponentItem item={{title: 'Aangepaste informatie', description: modified_information}}/>
        <ComponentItem item={{title: 'Geschiedenis', description: history}}/>
        <ComponentItem item={{title: 'Logboek wijzigingen', description: modified_information}}/>
      </CollapsableInformationField>
    </ul>
  );
};

export default GeneralInformation;

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Logout } from '../Shared/Data/LoginService';
import { jwtToken } from '../Models/Types/JwtToken';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  BadRequest = 502,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};

class Http {
  tokenGenerator: any;

  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: window.__RUNTIME_CONFIG__.BACKEND_BASEURL,
      headers,
      withCredentials: false,
    });

    http.interceptors.request.use(
      async (config) => {
        try {
          const token = localStorage.getItem('userToken');
          const newConfig = { ...config };

          if (token != null && newConfig.headers) {
            const { exp }: jwtToken = jwtDecode(token);
            const validTime = new Date(exp * 1000);
            if (validTime < new Date() && window.location.pathname !== '/') {
              Logout();
            }

            newConfig.headers.Authorization = `Bearer ${token}`;
          }

          return newConfig;
        } catch (error) {
          if (error instanceof Error) {
            throw new Error(error.message);
          } else {
            // to catch anything unexpected and prevent type errors
            throw new Error();
          }
        }
      },
      (error) => Promise.reject(error),
    );

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      },
    );

    this.instance = http;
    return http;
  }

  async getToken() {
    // We do the tokenGenerator null check because it won't be set when requesting a PDF, and that's okay
    return this.tokenGenerator && this.tokenGenerator({ audience: window.__RUNTIME_CONFIG__.BACKEND_BASEURL });
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  async post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  async patch<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  // eslint-disable-next-line class-methods-use-this
  private handleError(error: AxiosError) {
    const { status } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      case StatusCode.Forbidden:
      default: {
        // Handle Forbidden
        break;
      }
    }

    return Promise.reject(error);
  }
}

const http = new Http();
export default http;

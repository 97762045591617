function handleError(err: any, customMessage?: string) {
  if (customMessage) {
    return customMessage;
  }
  const { data, status } = err;
  let errMessage: string;

  if (data !== undefined) {
    switch (status) {
      case 403:
        errMessage = 'Je hebt niet de juiste rechten. Log opnieuw in of neem contact op met de beheerder.';
        break;
      case 404:
        errMessage = 'Data niet gevonden.';
        break;
      case 422:
        errMessage = 'Niet alle (verplichte) velden zijn correct ingevuld.';
        break;
      case 500:
        errMessage = 'Er ging iets fout op onze server.';
        break;
      default:
        errMessage = 'Er ging iets fout';
        break;
    }
  } else {
    errMessage = 'Er ging iets fout';
  }
  return errMessage;
}

export default handleError;

import React, { FormEvent, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import InputField from '../../Shared/Components/Form/InputField';
import Button from '../../Shared/Components/Button/Button';
import { ButtonSizes, ButtonTypes } from '../../Shared/Components/Button/enums';
import { Login } from '../../Shared/Data/LoginService';
import { getUser } from '../../Shared/Data/UserService';
import useBem from '../../Hooks/useBem';
import Modal from '../../Shared/Components/Modal/Modal';

import './Login.scss';

const SubstancesRegister = () => {
  const [bemClassName, bemClassNames] = useBem('login');
  const [showModal, setShowDModal] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined | null>(null);

  if (localStorage.getItem('userToken') !== null) {
    getUser()
      .catch((err) => {
        const { status } = err;
        switch (status) {
          case 403:
            setError('Sessie is verlopen. Opnieuw inloggen is vereist.');
            localStorage.removeItem('userToken');
            localStorage.removeItem('user');
            break;
          default:
            setError('Er ging iets mis.');
            break;
        }
      })
      .then((userData) => {
        if (userData !== undefined) {
          navigate('/productenregister');
        }
      });
  }

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();

    setError(undefined);

    const target = event.target as typeof event.target & {
      username: { value: string };
      password: { value: string };
    };

    const {
      username: { value: user },
      password: { value: pwd },
    } = target;

    const loginData = await Login({
      name: user,
      pass: pwd,
    }).catch((err) => {
      const { data } = err;
      if (data !== undefined) {
        const { message } = data;

        if (message === 'Sorry, unrecognized username or password.') {
          setError('Onbekende gebruiker of wachtwoord.');
        } else {
          setError(message);
        }
      } else {
        setError('Er ging iets fout');
      }
    });

    if (loginData !== undefined) {
      const { token } = loginData;
      localStorage.setItem('userToken', token);
      const { access, roles } = jwtDecode(token) as { access: string[]; roles: string[] };

      getUser().then((userData) => {
        // eslint-disable-next-line no-param-reassign
        userData.rights = access;
        // eslint-disable-next-line no-param-reassign
        userData.roles = roles;

        localStorage.setItem('user', JSON.stringify(userData));
        navigate('/productenregister');
      });
    }
  };

  return (
    <div>
      <section className={bemClassName()}>
        <form
          className={bemClassName('form')}
          onSubmit={(event) => handleLogin(event)}
        >
          <div className={bemClassName('logo')}>
            <img
              src="/Images/mpzLogo.svg"
              alt="Arrow left"
            />
          </div>
          <div className={bemClassName('input')}>
            <InputField
              name="username"
              label="Inloggen"
              placeholder="Gebruikersnaam"
              preventDefault={false}
            />
            <InputField
              name="password"
              label=""
              placeholder="Wachtwoord"
              type="password"
              preventDefault={false}
            />
          </div>
          {error !== undefined && (
            <div>
              <span className={bemClassName('error')}>{error}</span>
            </div>
          )}
          {error === undefined ? (
            <div className={bemClassName('loading')}>
              <InfinitySpin
                width="100"
                color="#5776B0"
              />
            </div>
          ) : (
            <Button
              title="Inloggen"
              theme={ButtonTypes.SolidBlue}
              width={ButtonSizes.Full}
              submit
            />
          )}

          <div className={bemClassName('footer')}>
            <button
              type="button"
              className={bemClassNames([bemClassName('footer-button'), 'stripped-button'])}
              onClick={() => setShowDModal(true)}
            >
              Disclaimer
            </button>
            <button
              type="button"
              className={bemClassNames([bemClassName('footer-button'), 'stripped-button'])}
              onClick={() => {
                // TODO change mail
                document.location = 'mailto:support@milieuplatformzorg.nl';
              }}
            >
              Toegang aanvragen
            </button>
          </div>
        </form>
      </section>
      <Modal
        showModal={showModal}
        modalTitle="Disclaimer"
        modalDescription="De gevaarlijke stoffen applicatie is met zorg samengesteld, maar Stichting Milieu Platfom Zorgsector garandeert niet dat het systeem foutloos, volledig en actueel is. Het gebruik van de applicatie is op eigen risico. Stichting Milieu Platfom Zorgsector is niet aansprakelijk voor eventuele schade of gevolgen die voortvloeien uit de informatie die op of via deze applicatie wordt verstrekt.Aan de informatie op de applicatie kunnen geen rechten of aanspraken worden ontleend. "
        modalButtonLeftText="Sluiten"
        onLeftAction={() => setShowDModal(false)}
        onHandleClose={() => setShowDModal(false)}
        modalButtonRightText=""
        onRightAction={() => {}}
      />
    </div>
  );
};

export default SubstancesRegister;
